import { Divider, Flex } from 'antd';
import React, { useState } from 'react';

import { Navigation } from '@/app/_types/components/navigation';
import { NavigationItemChildrenM2M } from '@/app/_types/components/navigation-item';
import { NavigationM2A } from '@/app/_types/header';

import Styles from './MegaMenu.module.scss';

type Props = {
  items?: NavigationItemChildrenM2M[] | null;
  staticItems?: NavigationM2A[] | null;
};

const StaticLinks = ({ items }: { items?: Navigation[] | null }) => {
  if (!items) return null;

  return (
    <ul className={Styles.quickLinkList}>
      {items.map((item, index) => (
        <li key={index}>
          <a href={item.permalink}>{item.text}</a>
        </li>
      ))}
    </ul>
  );
};

const DesktopMenu = ({ items, staticItems }: Props) => {
  const popoverWidth = document.getElementsByTagName('body')[0].offsetWidth;
  const [seeAll, setSeeAll] = useState<{ [key: string]: boolean }>({});
  const toggleSeeAll = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSeeAll((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const itemsWithoutChildren = items?.filter(
    (item) =>
      !item.related_navigation_items_id.children ||
      item.related_navigation_items_id.children.length === 0,
  );

  const itemsWithChildren = items?.filter(
    (item) =>
      item.related_navigation_items_id.children &&
      item.related_navigation_items_id.children.length > 0,
  );

  const hasStaticLinks = staticItems && staticItems.length > 0;

  return (
    <div className={Styles.megaMenuWrapper} style={{ width: popoverWidth }}>
      <Flex className={Styles.megaMenuContainer}>
        <div className={Styles.leftMenuContent}>
          {itemsWithChildren?.map((item, index: number) => {
            const { Title, navigation, new_tab } =
              item.related_navigation_items_id;
            const href = navigation.permalink || '';
            const isSeeAll = seeAll[index];
            const childrenCount =
              item.related_navigation_items_id.children?.length || 0;

            const maxVisibleItems = isSeeAll ? childrenCount : 7;

            return (
              <div key={index} className={Styles.menuListColumn}>
                <a
                  href={href}
                  className={Styles.menuListLink}
                  target={new_tab ? '_blank' : '_self'}
                >
                  {Title}
                </a>
                {item.related_navigation_items_id.children && (
                  <ul className={Styles.subMenu}>
                    {item.related_navigation_items_id.children
                      .slice(0, maxVisibleItems)
                      .map((childItem, childIndex: number) => {
                        const { Title, navigation, new_tab } =
                          childItem.related_navigation_items_id;
                        const href = navigation.permalink || '';

                        return (
                          <li key={childIndex}>
                            <a
                              href={href}
                              className={Styles.subLink}
                              target={new_tab ? '_blank' : '_self'}
                            >
                              {Title}
                            </a>
                          </li>
                        );
                      })}

                    {item.related_navigation_items_id.children.length > 7 && (
                      <li>
                        <button
                          className={Styles.seeAllLink}
                          onClick={(e) => toggleSeeAll(index, e)}
                        >
                          {isSeeAll ? 'See Less' : 'See All'}
                        </button>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            );
          })}

          {itemsWithoutChildren && itemsWithoutChildren.length > 0 && (
            <div className={Styles.menuListColumn}>
              {itemsWithoutChildren?.map((item, index) => {
                const { Title, navigation, new_tab } =
                  item.related_navigation_items_id;
                const href = navigation.permalink || '';

                return (
                  <a
                    key={index}
                    href={href}
                    target={new_tab ? '_blank' : '_self'}
                    className={Styles.menuListLink}
                  >
                    {Title}
                  </a>
                );
              })}
            </div>
          )}
        </div>

        {hasStaticLinks && (
          <div className={Styles.quickLinkContent}>
            {staticItems.map((itemM2A, index) => {
              const itemKey = itemM2A.id;
              const item = itemM2A.item;
              const isLast = index === staticItems.length - 1;

              return (
                <React.Fragment key={itemKey}>
                  <p className={Styles.quickLinkTitle}>{item.text}</p>
                  <StaticLinks items={item.nav_children} />
                  {!isLast && <Divider className={Styles.quickLinkDivider} />}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </Flex>
    </div>
  );
};

export default DesktopMenu;
