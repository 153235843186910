import { Button, Flex } from 'antd';
import Image from 'next/image';
import React from 'react';

import CloseIcon from '@/app/_icons/close.svg';

import Styles from './ContactUs.module.scss';

type Props = {
  closeMenu?: () => void;
  assistTitle: string;
  assistNumber: string;
  claimsTitle: string;
  claimsNumber: string;
};

const ContactUs = ({ closeMenu, assistTitle, assistNumber, claimsTitle, claimsNumber }: Props) => {
  const popoverWidth = document.getElementsByTagName('body')[0].offsetWidth;

  return (
    <div className={Styles.contactMenuWrapper} style={{ width: popoverWidth }}>
      <Flex
        justify="space-between"
        align="center"
        className={Styles.contactTitle}
      >
        <div className="headingElement">
          <h3 className="titleSm">Contact Us</h3>
        </div>
        <Button type="text" className={Styles.menuIconBtn} onClick={closeMenu}>
          <Image src={CloseIcon} alt="Contact" height={20} width={20} />
        </Button>
      </Flex>
      <ul className={Styles.contactList}>
        <li>
          {assistTitle} <a href={`tel:${assistNumber}`}>{assistNumber}</a>
        </li>
        <li>
          {claimsTitle} <a href={`tel:${claimsNumber}`}>{claimsNumber}</a>
        </li>
      </ul>
    </div>
  );
};

export default ContactUs;
