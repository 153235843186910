import Link from 'next/link';
import React from 'react';

import { NavigationItemM2M } from '@/app/_types/footer';

import Styles from './FooterMenu.module.scss';

type Props = {
  items?: NavigationItemM2M[] | null;
};

const FooterMenu = ({ items }: Props) => {
  return (
    <div className={Styles.footerMenuWrapper}>
      {items &&
        items.map((itemM2M) => {
          const { Title, children, navigation, new_tab } =
            itemM2M.navigation_items_id;
          const href = navigation.permalink;

          return (
            <div key={itemM2M.id} className={Styles.footerMenuItem}>
              <a
                href={href}
                target={new_tab === true ? '_blank' : '_self'}
                className={Styles.footerMenuTitle}
              >
                {Title}
              </a>
              {children && (
                <ul className={Styles.subMenu}>
                  {children.map((child) => {
                    const { Title, navigation } =
                      child.related_navigation_items_id;
                    const href = navigation.permalink;

                    return (
                      <li key={child.id}>
                        <Link href={href} className={Styles.subLink}>
                          {Title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default FooterMenu;
