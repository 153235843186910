'use client';

import { RightOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import cn from 'classnames';
import Link from 'next/link';
import { useState } from 'react';

import {
  NavigationItem,
  NavigationItemChildrenM2M,
} from '@/app/_types/components/navigation-item';
import { NavigationItemM2M } from '@/app/_types/footer';

import Styles from './FooterMenuMobile.module.scss';

type Props = {
  items?: NavigationItemM2M[] | null;
};

type MenuItem = Required<MenuProps>['items'][number];

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const FooterMenuMobile = ({ items }: Props) => {
  const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);

  const transformMenuItem = (key: string, item: NavigationItem): MenuItem => {
    const { Title, navigation } = item;
    const href = navigation.permalink;

    return {
      key,
      label: href ? (
        <Link
          href={href}
          className={cn(
            Styles.menuLink,
            item.Title === 'See All' && Styles.seaAllLink,
          )}
          onClick={() => setStateOpenKeys([])}
        >
          {Title}
          <div className={'menuIcon'}>
            <RightOutlined />
          </div>
        </Link>
      ) : (
        <span className={Styles.menuLink}>{Title}</span>
      ),
      children:
        item.children && item.children.length > 0
          ? transformSubMenuData(item.children)
          : undefined,
    };
  };

  const transformMenuData = (items: NavigationItemM2M[]) => {
    return items.map(
      (itemM2A: NavigationItemM2M): MenuItem =>
        transformMenuItem(
          String(itemM2A.navigation_items_id.id),
          itemM2A.navigation_items_id,
        ),
    );
  };

  const transformSubMenuData = (items: NavigationItemChildrenM2M[]) => {
    return items.map(
      (item: NavigationItemChildrenM2M): MenuItem =>
        transformMenuItem(
          String(item.related_navigation_items_id.id),
          item.related_navigation_items_id,
        ),
    );
  };

  const menuItems: MenuItem[] = items ? transformMenuData(items) : [];

  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };

    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(menuItems as LevelKeysProps[]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );

    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <div className={Styles.subMenuWrapper}>
      <div className={Styles.subMenuContainer}>
        <Menu
          mode="inline"
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          items={menuItems}
          className={Styles.subMenuList}
          expandIcon={''}
        />
      </div>
    </div>
  );
};

export default FooterMenuMobile;
